import $ from "jquery";
import "whatwg-fetch";
import LazyLoad from "vanilla-lazyload";
$(document).ready(() => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: "img, .lazy",
  });

  document.getElementById("bg-video").play();
  document.getElementById("bg-video2").play();
  document.getElementById("bg-video3").play();
  document.getElementById("bg-video4").play();
  //必须在微信Weixin JSAPI的WeixinJSBridgeReady才能生效
  document.addEventListener(
    "WeixinJSBridgeReady",
    function () {
      document.getElementById("bg-video").play();
      document.getElementById("bg-video2").play();
      document.getElementById("bg-video3").play();
      document.getElementById("bg-video4").play();
    },
    false
  );

  const videoMaskDom = $(".video-mask");
  $(".case[data-video-src]").each((index, dom) => {
    $(dom).on("click", (event) => {
      const src = $(dom).attr("data-video-src");
      const videoDom = document.createElement("video");
      $(videoDom).attr("src", src);
      $(videoDom).attr("controls", true);
      $(videoMaskDom).find(".video-wrapper").append(videoDom);
      videoMaskDom.find("video")[0].play();
      videoMaskDom.removeClass("fold").addClass("show");
      $("body").css("overflow", "hidden");
    });
  });

  videoMaskDom.find(".close").on("click", () => {
    videoMaskDom.removeClass("show").addClass("fold");
    setTimeout(() => {
      videoMaskDom.find("video").remove();
    }, 500);
    $("body").css("overflow", "auto");
  });

  // 微信分享
  const url = location.href.split("#")[0];
  fetch("http://wechatservice.daguchuangyi.com/jssdk", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      url: url,
      wechatid: "585cab1b49e1b82c8b8f6086",
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      console.log(data);
      wx.config({
        debug: false,
        appId: data.appid,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
      });
      wx.ready(() => {
        wx.onMenuShareTimeline({
          title: "一分钟了解 vertex.studio",
          link: location.href,
          imgUrl:
            "https://zhinan-vertex.oss-cn-shanghai.aliyuncs.com/immersion/logo.jpg",
        });
        wx.onMenuShareAppMessage({
          title: "一分钟了解 vertex.studio",
          desc: "科技质感",
          link: location.href,
          imgUrl:
            "https://zhinan-vertex.oss-cn-shanghai.aliyuncs.com/immersion/logo.jpg",
        });
      });
    });
});
